import React from "react"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import TitleBannerEnquiry from "../components/titleBanner/TitleBannerEnquiry"
import CardsList from "../components/common/cardsList/CardsList"

const CoursesSkillsFuture = ({ data }) => {
  const { skillsfutureCourses } = data
  return (
    <div>
      <SEO
        title="WSQ Courses"
        description="Receive up to 70% funding for Skillsfuture (SSG) approved BIM and CAD courses in Singapore."
      />

      <TitleBannerEnquiry
        title="WSQ Courses"
        description="Receive up to 70% funding for Skillsfuture (SSG) approved BIM and CAD courses in Singapore."
        withOnlyDescription
      />

      <div className="container">
        <CardsList
          courses={skillsfutureCourses.nodes}
          withoutFilter
        />
      </div>
    </div>
  )
}

export const awsQuery = graphql`
  query allSkillsFutureCoursesQuery {
    skillsfutureCourses: allContentfulTraining(
      filter: { category: { eq: "SkillsFuture" } }
    ) {
      nodes {
        id
        title
        category
        courseReference
        trainingMode
        updatedAt
        slug
        skillsFramework
        requirements
        lessons
        product {
          id
          name
          netFee
          price
        }
        duration
        coverPhoto {
          gatsbyImageData
        }
        courseRuns
      }
    }
  }
`

export default CoursesSkillsFuture
